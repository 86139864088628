export const mediaBreakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1300,
};

export const getMediaBreakpoint = (bp: string) => {
  switch (bp) {
    case 'sm':
      return mediaBreakpoints.sm;
    case 'md':
      return mediaBreakpoints.md;
    case 'lg':
      return mediaBreakpoints.lg;
    case 'xl':
      return mediaBreakpoints.xl;
  }
};

export const mediaBP = (breakpoint: string) => {
  if (breakpoint === 'xs' && window.innerWidth < mediaBreakpoints.sm) {
    return true;
  }
  if (window.innerWidth >= getMediaBreakpoint(breakpoint)) {
    return true;
  }
  return false;
};

export const isMediaBP = (breakpoint:string) => {
  if (window.innerWidth >= getMediaBreakpoint(breakpoint)) {
    return false;
  }
  return true;
};


export const getHotelRatingOpacity = (rating:number)  => {
  if (rating < 4 && rating >= 1) {
    return 40;
  } else if (rating < 7 && rating >= 4) {
    return 70;
  }
  return 100;
}
