import * as React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';
const urlParse = require('url-parse');

// Utils
import {
  isNil,
  isNilOrEmpty,
  verifyProperty,
} from '@src/shared/src/util/general';
import {
  getUserFullname,
} from '@src/shared/src/util/users';
import {
  getPolicyViolationMessage,
  navigate,
} from '@toolkit/util/app';
import { t } from '@toolkit/util/i18n';
import { formatDate, formatDateRange } from '@src/shared/src/util/date';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
// Constants
import {
  ENVIRONMENT,
  STATUS,
} from '@src/shared/src/const/app';
import { CHECKOUT_STEPS, ROUTES } from '@toolkit/const/app';
import { API_URL } from '@src/shared/src/const/api';
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';

// Actions
import { selectors } from '@src/shared/src';
// Models
import {
  BasketItemModel,
  BasketModel,
  BookingItemModel,
  BookingModel,
  PolicyViolationModel,
  SearchModel,
  TravelBookingModel,
  RentalModel,
  RedeemingAllowanceModel,
  PassengerModel,
} from '@src/shared/src/models';
// Interfaces
import { ConnectedRedux, IRootState } from '@src/store';
import { StepParam, SearchIdParam } from '@src/routes/IMatchParams';
// Components
import CheckoutSideBarConn from './CheckoutSideBarConn';
import CheckoutReserveConn from './CheckoutReserveConn';
import CheckoutPrepareConn from './CheckoutPrepareConn';
import CheckoutPaymentConn from './CheckoutPaymentConn';
import CheckoutConfirmConn from './CheckoutConfirmConn';
import { CheckoutTimer, RedeemedSearchNotification, ShowLastErrorStatus, CheckoutOverlayMessages } from '@pod/checkout/components';
import {
  InformationOverlay,
  LoaderOverlay,
  WarningBanner,
  BENotificationBar,
  Navbar,
  LoaderProgress,
  RebookingBanner,
} from '@toolkit/ui';
import { RequiresAllowanceWarning } from '../components/RequiresAllowanceWarning';
import { ExceedsAllowanceWarning } from '../components/ExceedsAllowanceWarning';
// Styles

type Props = ConnectedRedux<IRootState> & RouteComponentProps<SearchIdParam & StepParam> & AppContextProp & {
  basket:BasketModel;
  basketItems:BasketItemModel[];
  search:SearchModel;
  passengers:PassengerModel[];
  totalCost:number;
  currentBooking:BookingModel;
  travelBooking:TravelBookingModel;
  currentBookingItems:BookingItemModel[];
  hasConfirmFailedItems:boolean;
  hasConfirmPendingItems:boolean;
  hasHardFailedItems:boolean;
  policyViolations:PolicyViolationModel[];
  preparedAt:Date;
  seatMapSelectorUrl:string;
  bookingRental:RentalModel;
  redeemingAllowance: RedeemingAllowanceModel;
};

type State = {
  env:ENVIRONMENT;
  showTimerExpiredOverlay:boolean;
  showDownloadICal:boolean;
  hotelRemarks:string;
  isJourney:boolean;
};

class CheckoutConn extends React.Component<Props, State> {

  constructor(props:Props) {
    super(props);
    this.state = {
      env: ENVIRONMENT.CHECKOUT,
      showTimerExpiredOverlay: false,
      showDownloadICal: false,
      hotelRemarks:undefined,
      isJourney: this.getIsJourney(),
    }
  }

  getIsJourney = () => {
    let isJourney = urlParse(this.props.location.search, true);
    isJourney = verifyProperty(false, isJourney, ['query', 'is_journey']);
    return (isJourney === 'true');
  }

  private isFailedOverlayVisible = (basketStatus:STATUS) => {
    switch (basketStatus) {
      case STATUS.FAILED:
      case STATUS.PREPARE_FAILED:
        return true;
      default:
        return false;
    }
  }

  private getEnvironemntByBasketStatus = (basketStatus:STATUS) => {
    switch (basketStatus) {
      case STATUS.FRESH:
      case STATUS.FAILED:
      case STATUS.PREPARE_FAILED:
      case STATUS.PREPARE_FINISHED:
      case STATUS.IN_PROGRESS:
        return ENVIRONMENT.CHECKOUT;
      case STATUS.CONFIRM_FAILED:
      case STATUS.CONFIRM_PENDING:
      case STATUS.CONFIRM_FINISHED:
      case STATUS.FINISHED:
        return ENVIRONMENT.CONFIRMATION;
      default:
        return ENVIRONMENT.CHECKOUT;
    }
  }

  private renderPolicyViolations = () => {
    if (!isNilOrEmpty(this.props.policyViolations)) {
      return (
        <WarningBanner
          label={
            <div>
                <p>
                  {t('checkoutConn.policy.warning.title', {
                    userName: getUserFullname(this.props.passengers[0]),
                  })}
                </p>
                <ul className="error">
                  {this.props.policyViolations.map((warning, index) =>
                    <li key={`$warning-${index}`}>
                      <i className="icon-close2" />{getPolicyViolationMessage(warning)}
                    </li>
                  )}
                </ul>
            </div>
          }
          icon="lp-icon-warning"/>
      );
    }
  }

  private showDownloadICalBtn = (bookingId: string) => {
    if ((window as any).__IS_WEBVIEW) {
      return <div className="content-checkout-ical"><strong>{t('checkoutSideBarConfirmationInfo.text.ios.addToCalendar')}</strong></div>;
    } else {
      return (
        <div className="content-checkout-ical">
          <a className="tcp-link" href={`${API_URL.ORG_ADMIN_TRAVEL_BOOKINGS}/${bookingId}/ics`}>
            <i className="icon-add_calendar" />
            {t('checkoutSideBarConfirmationInfo.button.addToCalendar')}
          </a>
        </div>
      );
    }
  }

  public render() {
    if (isNil(this.props.basket) || isNilOrEmpty(this.props.passengers)) {
      return (
        <LoaderOverlay
          showSpinner={true}
          title={t('global.processingTrip')}/>
      );
    }

    const basketEditable:boolean = verifyProperty(null, this.props.basket, ['editable']);

    const { match, appContext, search, currentBooking } = this.props;

    const basketId = this.props.search.basketId;
    const searchId = this.props.search.id;
    const basketStatus = this.props.basket.status as STATUS;
    const totalPrice = verifyProperty(null, this.props.currentBooking, ['totalPrice']);
    const bookingId = verifyProperty(null, this.props.currentBooking, ['id']);
    const possibleCheaperFlight = verifyProperty(null, currentBooking, ['askSupportIfCheaperTicketPossible']);
    const requiresAllowance = verifyProperty(null, currentBooking, ['requiresAllowance']);
    const exceedsAllowance = verifyProperty(null, currentBooking, ['exceedsAllowance']);
    const redeemingAllowance = verifyProperty(null, currentBooking, ['redeemingAllowance']);

    return (
      <React.Fragment>
        <ShowLastErrorStatus />
        <div className="main-container">
          <BENotificationBar currentPage="Booking" />
          {basketStatus === STATUS.IN_PROGRESS && <LoaderProgress finished={false}/>}
            <div className="container">
              <div className="row">
              {appContext.isMediaBPTabletUp ?
                <CheckoutSideBarConn
                  env={this.getEnvironemntByBasketStatus(basketStatus)}
                  isBasketEditable={basketEditable}
                  exceedsAllowance={exceedsAllowance}
                  bookingId={bookingId}
                  onTimerExpired={() => this.setState({ showTimerExpiredOverlay: true })}/>
                  :
                <Navbar showBackButton={basketEditable} iconRight={basketStatus ===  STATUS.CONFIRM_FINISHED ? 'icon-youtube_searched_for' : 'icon-edit'} actionRight={() => navigate('/')} theme="checkout">
                  <div>
                    {t('checkoutConn.navbar.title')}
                    <div className="lp-navbar-subtitle">
                      {search.arrAt
                        ? formatDateRange(search.depAt, search.arrAt, '-', DATE_FORMAT_TYPES.DAY_SHORT_DATE)
                        : formatDate(search.depAt, DATE_FORMAT_TYPES.DAY_SHORT_DATE)
                      }
                    </div>
                  </div>
              </Navbar>
            }
              <section className="content content-checkout">
                {/*TODO: merge all checkout messages into CheckoutMessages component */}
                {/* <CheckoutMessages/> */}
                <RebookingBanner />
                {(this.props.hasConfirmFailedItems || this.props.hasConfirmPendingItems) &&
                  !this.props.hasHardFailedItems && (
                    <WarningBanner
                      icon="lp-icon-help"
                      isInfo={true}
                      label={
                        <Trans i18nKey="CheckoutConn.failed.info.message">
                          <b>bold text</b>
                        </Trans>
                      }
                    />
                  )}
                {verifyProperty(false, this.props.bookingRental, ['arrInformation']) &&
                  <WarningBanner
                    icon="lp-icon-help"
                    isInfo={true}
                    label={this.props.bookingRental.arrInformation}/>
                }
                {!exceedsAllowance &&
                  <RedeemedSearchNotification redeemingAllowance={this.props.redeemingAllowance} />
                }
                <RequiresAllowanceWarning requiresAllowance={requiresAllowance}/>
                <ExceedsAllowanceWarning
                  exceedsAllowance={exceedsAllowance}
                  redeemingAllowance={redeemingAllowance} />
                {!appContext.isMediaBPTabletUp && basketStatus === STATUS.PREPARE_FINISHED ?
                 <CheckoutTimer
                  onTimerExpired={() => this.setState({ showTimerExpiredOverlay: true })}
                  bookingCreatedAt={this.props.preparedAt}/> : ''
                }
                {(!appContext.isMediaBPTabletUp && basketStatus === STATUS.CONFIRM_FINISHED) &&
                  this.showDownloadICalBtn(bookingId)
                }
                {this.renderPolicyViolations()}
                <Switch>
                  <Route
                    path={`${match.path}${CHECKOUT_STEPS.RESERVE}/:searchId`}
                    render={
                      (props) =>
                        <CheckoutReserveConn
                          env={ENVIRONMENT.CHECKOUT}
                          totalPrice={totalPrice}
                          basketStatus={basketStatus}
                          basketId={basketId}
                          searchId={searchId}/>
                    }/>
                  <Route
                    path={`${match.path}${CHECKOUT_STEPS.PREPARE}/:searchId`}
                    render={
                      (props) =>
                        <CheckoutPrepareConn
                          env={ENVIRONMENT.CHECKOUT}
                          totalPrice={totalPrice}
                          bookingItems={this.props.currentBookingItems}
                          basketItems={this.props.basketItems}
                          basketStatus={basketStatus}
                          onChangeHotelRemarks={(hotelRemarks) => this.setState({ hotelRemarks })}
                          seatMapSelectorUrl={this.props.seatMapSelectorUrl}
                          bookingId={bookingId}
                          possibleCheaperFlight={possibleCheaperFlight}
                          requiresAllowance={requiresAllowance}
                          exceedsAllowance={exceedsAllowance}
                          searchId={searchId}/>
                    }/>
                  <Route
                    path={`${match.path}${CHECKOUT_STEPS.PAYMENT}/:searchId`}
                    render={
                      (props) =>
                        <CheckoutPaymentConn
                          basketId={basketId}
                          totalPrice={totalPrice}
                          basketStatus={basketStatus}
                          policyViolations={this.props.policyViolations}
                          hotelRemarks={this.state.hotelRemarks}
                          searchId={searchId}/>
                    }/>
                  <Route
                    path={`${match.path}${CHECKOUT_STEPS.CONFIRM}/:searchId`}
                    render={
                      (props) =>
                        <CheckoutConfirmConn
                          env={ENVIRONMENT.CONFIRMATION}
                          totalPrice={totalPrice}
                          bookingItems={this.props.currentBookingItems}
                          basketStatus={basketStatus}
                          isJourney={this.state.isJourney}
                          searchId={searchId}/>
                    }/>
                </Switch>
              </section>
            </div>
            <div>
              {/*TODO: merge all information overlay stuff to here */}
              <CheckoutOverlayMessages
                isJourney={this.state.isJourney}/>
              <InformationOverlay
                key={`info-overlay-failed-${this.isFailedOverlayVisible(basketStatus)}`}
                isOpen={this.isFailedOverlayVisible(basketStatus)}
                icon="icon-radio_button_unchecked2 color-warning"
                title={t('informationOverlay.title.basketFailed')}
                body={t('informationOverlay.body.basketFailed')}
                showButton={true}
                buttonText={t('informationOverlay.button.basketFailed')}
                onButtonClicked={() => navigate(ROUTES.HOME)}/>
              <InformationOverlay
                key={`info-overlay-confirm-failed`}
                isOpen={
                  (this.props.hasConfirmFailedItems || this.props.hasConfirmPendingItems) &&
                  !this.props.hasHardFailedItems
                }
                icon="icon-radio_button_unchecked2 color-blue"
                title={''}
                body={
                  <Trans i18nKey="CheckoutConn.failed.info.message">
                    <b>bold text</b>
                  </Trans>
                }
                showButton={true}
                buttonText={t('informationOverlay.button.continue')}/>
              <InformationOverlay
                key="info-overlay-confirm-hard-failed"
                isOpen={this.props.hasHardFailedItems}
                icon="icon-repeat_overlay color-warning"
                title=""
                body={t('CheckoutConn.hardFailed.info.message')}
                showButton={true}
                buttonText={t('informationOverlay.button.proceedTimerExpired')}
                onButtonClicked={() => navigate(ROUTES.HOME)}/>
              <InformationOverlay
                key={`info-overlay-timer-expired-${this.state.showTimerExpiredOverlay}`}
                isOpen={this.state.showTimerExpiredOverlay}
                icon="icon-repeat_overlay color-warning"
                title={t('informationOverlay.title.timerExpired')}
                body={
                  redeemingAllowance
                  ? <form
                      method="post"
                      action={`${API_URL.DEFAULT}/org_admin/allowances/${redeemingAllowance.id}/redeem`}>
                      <button className="tcp-loader-overlay-button button" type="submit">
                        {t('exceedsAllowanceWarning.exceedsAllowance.link.redeem')}
                      </button>
                    </form>
                  : <Trans i18nKey="informationOverlay.body.timerExpired">
                      <br/>
                    </Trans>}
                showButton={redeemingAllowance ? false : true}
                buttonText={t('informationOverlay.button.proceedTimerExpired')}
                onButtonClicked={() => navigate(ROUTES.HOME)}/>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = (state:IRootState) => ({
  basket: state.checkout.basket,
  basketItems: selectors.checkout.basketItems(state.checkout),
  search: selectors.search.search(state.search),
  passengers: selectors.search.searchPassengers(state.search),
  totalCost: selectors.checkout.totalCost(state.checkout),
  currentBooking: state.checkout.currentBooking,
  travelBooking: state.checkout.travelBooking,
  currentBookingItems: state.checkout.currentBookingItems,
  hasConfirmFailedItems: selectors.checkout.hasConfirmFailedItems(state.checkout),
  hasConfirmPendingItems: selectors.checkout.hasConfirmPendingItems(state.checkout),
  hasHardFailedItems: selectors.checkout.hasHardFailedItems(state.checkout),
  policyViolations: selectors.checkout.policyViolations(state.checkout),
  preparedAt: selectors.checkout.preparedAt(state.checkout),
  seatMapSelectorUrl: state.checkout.seatMapSelectorUrl,
  bookingRental: selectors.checkout.rental(state.checkout),
  redeemingAllowance: state.checkout.redeemingAllowance,
});
export default connect(mapState)(withAppContext(CheckoutConn));
