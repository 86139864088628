// NOTE: Currenlty this component can only be used with HotelFeatureFilter!
import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { isNil } from '@src/shared/src/util/general';
import { SelectionModel } from '@src/models';
import { CheckboxFilter } from './CheckboxFilter';
import { IRootState } from '@src/store';

type Props = {
  title: string;
  options: SelectionModel[];
  isOpen?: boolean;
  isDisabled?: boolean;
  onChange(newOptions: SelectionModel[]): void;
  categoryTranslation?(categoryName: string): string;
}

export const CheckboxSelectionFilter: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = React.useState(isNil(props.isOpen) ? true : props.isOpen)
  //TODO: remove when we have language sorted out
  const userLanguage = useSelector((state: IRootState) => state.adminUser.profile.preference.language).split('-')[0]

  const isSelectionCurrent = (selection: SelectionModel, current: SelectionModel) => current.category === selection.category && current.enName === selection.enName;

  const onChangeSelection = (selection: SelectionModel, isSelected: boolean) => {
    const newOptions = props.options.map(option => isSelectionCurrent(selection, option)
      ? { category: option.category, enName: option.enName, deName: option.deName, isSelected }
      : option
    )
    props.onChange(newOptions)
  }

  const onChangeSelectionOnly = (selection: SelectionModel) => {
    const newOptions = props.options.map(option => isSelectionCurrent(selection, option)
      ? { category: option.category, enName: option.enName, deName: option.deName, isSelected: true }
      : { category: option.category, enName: option.enName, deName: option.deName, isSelected: false }
    )
    props.onChange(newOptions)
  }

  const categories = Array.from(new Set(props.options.map(option => option.category))).sort((currOpt, _) => currOpt === 'fare' ? -1 : 0)

  const containerStyle = classNames('sidebar-section', {
    'is-open': isOpen,
  });
  const indicatorStyle = classNames('sidebar-section-indicator', {
    'sidebar-section-indicator-disabled': props.isDisabled,
  });

  if (props.options.length === 0) {
    return null;
  }

  return (
    <div className={containerStyle}>
      <button className="sidebar-section-title" onClick={(_) => props.isDisabled ? null : setIsOpen(!isOpen)}>
        {props.title}
        <i className={indicatorStyle} aria-hidden="true" />
      </button>
      <div className="sidebar-section-inner">
        {categories.map(category =>
          <div key={category}>
            <div className="sidebar-section-headline"><strong>{props.categoryTranslation ? props.categoryTranslation(category) : category}</strong></div>
            {
              props.options.filter(option => option.category === category).map((option) =>
                <CheckboxFilter
                  key={`sel-id-${option[`${userLanguage}Name`]}`}
                  id={`sel-id-${option[`${userLanguage}Name`]}`}
                  label={option[`${userLanguage}Name`]}
                  isChecked={option.isSelected}
                  onChange={val => onChangeSelection(option, val)}
                  onChangeOnly={val => onChangeSelectionOnly(option)}
                />
              )
            }
          </div>
        )}
      </div>
    </div>
  );
}
